import config from '../../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* eslint-disable no-unused-vars */
import {RuxButton} from '@astrouxds/rux-button/rux-button.js';

/*

  To make use of external HTML templates and CSS import them
  via standard ES6 import statements.

  Note: This is not a true import Webpack will copy the
  content of each file when it generates its build bundle

*/
import template from './detail-pane.html';
import css from './detail-pane.css';
import astroCSS from '../astro-css.js';

const contactData = require('../contacts/contact-data.js');
const utils = require('../common-utils.js');
/* eslint-enable no-unused-vars */

/**
 * @polymer
 * @extends HTMLElement
 */
export class DetailPane extends PolymerElement {
  // Properties in HTML should be hyphenated, when referenced
  // in Javascript use camelCase and remove the hyphen
  static get properties() {
    return {
      open: {
        type: Boolean,
        reflectToAttribute: true,
        value: function() {
          return false;
        },
      },
      passDetail: {
        type: Object,
        value: function() {
          return {};
        },
      },
      passPlanSteps: {
        type: Array,
        value: () => {
          return [];
        },
      },
      detailView: {
        type: Array,
        value: [
          {
            label: 'Contact Details',
            contact: true,
            selected: true,
          },
          {
            label: 'Pass Plan',
            contact: false,
          },
        ],
      },
      currentTime: {
        type: Date,
        value: function() {
          return new Date();
        },
      },
    };
  }

  resetPane() {
    this.set('detail', null);
  }

  closePane() {
    this.resetPane();
    this.dispatchEvent(
        new CustomEvent('closeDetailPane', {
          bubbles: true,
          composed: true,
        })
    );
    return false;
  }

  constructor() {
    super();

    /* Register utility methods */

    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
    this.formatDayOfYear = utils.formatDayOfYear;
    this.mapPriority = utils.mapPriority;
    this.mapStatuses = utils.mapStatuses;
    this.investigateURL = config.menuItems[1].url;
  }

  _getDayOfYear(time) {
    return this._formatDayOfYear(time * 1000);
  }

  _getTime(time) {
    return this.formatReadableTime(time * 1000, 'utc');
  }

  _getUTCDateFrom(date) {
    return new Date(date.getFullYear(), date.getUTCMonth(), date.getUTCDate());
  }

  _expand(event) {
    const toggle = event.target.parentNode;
    toggle.toggleAttribute('expanded');
  }

  _formatMachineTime(timestamp) {
    const time = new Date(timestamp);

    /* eslint-disable max-len */
    const utc = `${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}`;
    const local = `${time.getFullYear()}-${time.getMonth()}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}`;
    /* eslint-enable no-unused-vars */

    return this.timezone.toLowerCase() === 'utc' ? utc : local;
  }

  _formatReadableTime(timestamp) {
    const time = new Date(timestamp);
    return new Date(time).toLocaleTimeString(this.locale, {
      timeZone: this.timezone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  }

  _formatDayOfYear(timestamp) {
    if (!timestamp) return;
    const now = this._getUTCDateFrom(new Date(timestamp));
    const year = new Date(now.getUTCFullYear(), 0, 0);

    const day = Math.round((now - year) / 86400000);

    return day.toString().padStart(3, '000');
  }

  mapAutomationMode(int) {
    switch (int) {
      case '1':
        return 'Semi-Automation';
      case '2':
        return 'Manual';
      default:
        return 'Full Automation';
    }
  }
  mapPriority(int) {
    switch (int) {
      case '1':
        return 'Medium';
      case '2':
        return 'High';
      default:
        return 'Low';
    }
  }
  mapStatuses(status) {
    switch (status) {
      case 'off':
        return 'inactive';
      case 'caution':
        return 'executing';
      case 'critical':
        return 'failed';
      case 'normal':
        return 'complete';
      default:
        return 'standby';
    }
  }

  is(a, b) {
    return a === b;
  }

  stringify(obj) {
    return JSON.stringify(obj);
  }

  /*

    See: https://polymer-library.polymer-project.org/3.0/docs/devguide/custom-elements#element-lifecycle

  */
  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('detail-pane', DetailPane);
