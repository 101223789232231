import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';

/* eslint-disable no-unused-vars */
import {RuxTimelineRegion} from './rux-timeline-region.js';
/* eslint-enable no-unused-vars */

import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if';

import template from './rux-timeline-track.html';
import css from './rux-timeline-track.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTimelineTrack extends PolymerElement {
  static get properties() {
    return {
      index: {
        type: Number,
        reflectToAttribute: true,
      },
      data: {
        type: Array,
      },
      label: {
        type: String,
      },
      scale: {
        type: Number,
      },
      filter: {
        type: Boolean,
      },
      duration: {
        type: Number,
      },
      selectedRegion: {
        type: Object,
        notify: true,
      },
      selected: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
  constructor() {
    super();

    this._windowListener = this._onWindowResize.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.trackWidth = this.shadowRoot.querySelectorAll('.rux-timeline__track')[0].offsetWidth;

    this.addEventListener('update', this._windowListener);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener('update', this._windowListener);
  }

  _onClick(e) {
    const _region = e.currentTarget;

    if (_region.hasAttribute('selected')) {
      this.selectedRegion = {};
    } else {
      const now = new Date();
      const utcNow = new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
      );

      let temporality = 'past';

      if (utcNow.getTime() > _region.startTime && utcNow.getTime() < _region.endTime) {
        temporality = 'present';
      } else if (utcNow.getTime() < _region.startTime) {
        temporality = 'future';
      }

      this.selectedRegion = {
        contactId: _region.contactId,
        label: _region.label,
        status: _region.status,
        startTime: _region.startTime,
        endTime: _region.endTime,
        detail: _region.detail,
        azimuth: _region.contactAzimuth,
        elevation: _region.contactElevation,
        temporality: temporality,
        state: _region.state,
        dom: _region,
      };
    }
  }

  _getRegions() {
    this.regions = this.data[0].passSlots.filter((region) => {
      return !region.reserved;
    });
  }

  _onWindowResize() {
    this.trackWidth = this.shadowRoot.querySelectorAll('.rux-timeline__track')[0].offsetWidth;
  }
}
customElements.define('rux-timeline-track', RuxTimelineTrack);
