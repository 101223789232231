import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {ContactItem} from './contact-item.js';
/* eslint-enable no-unused-vars */

import template from './current-contacts.html';
import css from './current-contacts.css';
const utils = require('../common-utils.js');

/**
 * @polymer
 * @extends HTMLElement
 */
export class CurrentContacts extends PolymerElement {
  static get properties() {
    return {
      potentialStates: {
        type: Array,
        value: [{stateName: 'all'}, {stateName: 'executing'}, {stateName: 'failed'}],
      },
      renderedItemCount: {
        type: Number,
      },
      log: {
        type: Array,
      },
      stateFilter: {
        type: String,
        value: 'all',
      },
      noFiltersSet: {
        type: Boolean,
        computed: 'areFiltersSet(stateFilter)',
      },
    };
  }

  /* Define component-specific methods */

  sortcontactsByStartTime(a, b) {
    return a.contactBeginTimestamp > b.contactBeginTimestamp ? 1 : -1;
  }

  areFiltersSet() {
    if (this.stateFilter !== 'all') return false;
    return true;
  }

  clearFilters() {
    this.stateFilter = 'all';
  }

  sortByStatus(a, b) {
    function mapStatus(status) {
      switch (status) {
        case 'critical':
          return 0;
        case 'caution':
          return 1;
        default:
          // normal
          return 2;
      }
    }
    return mapStatus(a.contactResolutionStatus) > mapStatus(b.contactResolutionStatus) ? 1 : -1;
  }

  sortByPropAsc(prop) {
    return function(a, b) {
      return a[prop] < b[prop] ? 1 : -1;
    };
  }

  sortByPropDes(prop) {
    return function(a, b) {
      return a[prop] > b[prop] ? 1 : -1;
    };
  }

  handleChangeSort(e) {
    this.changeSort(e.target.getAttribute('data-sort-by'));
  }

  changeSort(newSort) {
    const sortAsc = [];
    const sortDes = [
      'contactSatellite',
      'contactBeginTimestamp',
      'contactEndTimestamp',
      'contactGround',
      'contactResolutionStatus',
      'contactResolution',
    ];
    this.sortBy = newSort;
    if (sortDes.includes(this.sortBy)) {
      this.sortFn = this.sortByPropDes(this.sortBy);
    } else if (sortAsc.includes(this.sortBy)) {
      this.sortFn = this.sortByPropAsc(this.sortBy);
    } else {
      this.sortFn = this.sortByStatus;
    }
  }

  constructor() {
    super();
    /* Register utility methods */
    this.capitalize = utils.capitalize;
    this.is = utils.is;
    this.isPlural = utils.isPlural;
    this.changeSort('contactBeginTimestamp');
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('current-contacts', CurrentContacts);
