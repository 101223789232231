import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {AstroApp} from '../astro-app';
/* eslint-enable no-unused-vars */

import template from './status-indicators.html';
import css from './status-indicators.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class StatusIndicators extends PolymerElement {
  static get properties() {
    return {
      status: {
        type: Object,
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  _click(e) {
    this.dispatchEvent(
        new CustomEvent('showPopUpMenu', {
          detail: {
            target: e.currentTarget,
            menu: [
              {
                label: 'Investigate',
                action: 'notImplemented',
              },
            ],
          },
          bubbles: true,
          composed: true,
        })
    );
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }
}

customElements.define('status-indicators', StatusIndicators);
