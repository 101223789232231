import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

/* eslint-disable no-unused-vars */
import {RuxTimelineTrack} from './rux-timeline-track.js';
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';
/* eslint-enable no-unused-vars */

import template from './rux-timeline.html';
import css from './rux-timeline.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class RuxTimeline extends PolymerElement {
  static get properties() {
    return {
      label: {
        type: String,
        value: 'Timeline',
      },
      duration: {
        type: Number,
        value: 86400000,
      },
      views: {
        type: Array,
        value: [{label: 'Timeline', value: 'timeline'}, {label: 'List', value: 'list'}],
      },
      startTime: {
        type: Date,
        value: false,
      },
      status: {
        type: String,
        value: 'null',
      },
      tracks: {
        type: Array,
      },
      playbackControls: {
        type: String,
        value: false,
      },
      zoomControl: {
        type: Boolean,
        value: false,
      },
      playheadControl: {
        type: Boolean,
        value: false,
      },
      selected: {
        type: Object,
        notify: true,
      },
      selectedRegion: {
        type: Object,
        notify: true,
      },
      initialScale: {
        type: Number,
      },
      lockToPlayhead: {
        type: Boolean,
        value: true,
      },
      scale: {
        type: Number,
        observer: '_updateTimelineScale',
      },
      _scale: {
        type: Number,
        observer: '_updateTimelineScale',
      },
      timezone: {
        type: String,
        value: 'utc',
      },
    };
  }

  static get template() {
    return html([
      `
        <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();

    // set a default scale if one doesn’t exist
    if (!this.initialScale) this.initialScale = 100;

    // bind scroll listener to scroll event
    this._scrollListener = this._scroll.bind(this);

    // debounce for request animation frame
    this._last = 0;
  }

  connectedCallback() {
    super.connectedCallback();

    // hard coded min/max scale (for now)
    this._minScale = 100;
    this._maxScale = 5000;

    // get the playhead
    this._playhead = this.shadowRoot.getElementById('rux-timeline__playhead');

    // get the current time indicator
    this._currentTime = this.shadowRoot.getElementById('rux-timeline__current-time');

    // get the track container; this is the larger container for
    // tracks, ruler, playhead and current time indicator
    this._track = this.shadowRoot.getElementById('rux-timeline__viewport__track-container');
    this._track.addEventListener('scroll', this._scrollListener, {
      passive: true,
    });

    // get the timeline ruler
    this._ruler = this.shadowRoot.getElementById('rux-timeline__ruler');

    // get the tracks container; this differs from the track_container
    // in that it only contains the tracks.
    this._tracks = this.shadowRoot.getElementById('rux-timeline__viewport__tracks');

    // get the tracks label
    this._trackLabels = this.shadowRoot.getElementById('rux-timeline__viewport__labels');

    // if duration is less than 1000 then assume the data was in hours.
    // NOTE: Refactor the underscore _duration is redundant at this point
    // NOTE: a future enhancement would be to allow for some form of passing
    // time in e.g. 12h for 12 hours
    if (this.duration < 1000) {
      this.duration = this.duration * 60 * 60 * 1000;
    }
    this._duration = this.duration;
    this._durationHours = this._duration / 1000 / 60 / 60;

    this._scale = this.initialScale;

    if (this.playheadControl) {
      this._playhead.style.display = 'block';
      this.playheadAnimator = window.requestAnimationFrame(this._updatePlayhead.bind(this));
    }

    this._setTics();

    // if the browser supports the resizeObserver (currently Chrome 55+)
    // use that to handle both window and css transitions of width. This
    // provides a much smoother resize
    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver(() => {
        this._setParams();
      });

      this.resizeObserver.observe(this);

      // otherwise use a mutation observer to handle resizing via CSS/JS
      // and a window event listener to handle window resizing.
    } else {
      const _track = this.shadowRoot.getElementById('rux-timeline__viewport__track-container');

      this.mutationObserver = new MutationObserver(() => {
        this._setParams();
      });

      this.mutationObserver.observe(_track, {
        attributes: true,
        childList: false,
        subtree: true,
      });
    }

    // Animate the playhead
    this.currentTimeAnimator = window.requestAnimationFrame(this._updateCurrentTime.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this._track.removeEventListener('wheel', this._scrollListener);

    window.cancelAnimationFrame(this.currentTimeAnimator);
  }

  // @todo connect to a uer definable property
  _catchPlayhead() {
    const scrollX = this._currentTime.offsetLeft - this._track.offsetWidth / 2;
    this._track.scrollTo(scrollX, this._track.scrollTop);
  }

  _onClick(e) {
    /*
     TODO: Needs a refactor, probably use the concept from tabs/tab panels
     of registering a track to its track label instead of this song and dance
    */

    const _label = e.currentTarget;
    const _track = this.shadowRoot.querySelector(`[aria-labelledby="label-${_label.getAttribute('index')}"]`);

    _track.selected = !_track.selected;
    if (_track.selected) {
      _label.setAttribute('selected', '');
    } else {
      _label.removeAttribute('selected');
    }
  }

  _getUTC(time) {
    const _time = new Date(time * 1000);
    return new Date(
        _time.getUTCFullYear(),
        _time.getUTCMonth(),
        _time.getUTCDate(),
        _time.getUTCHours(),
        _time.getUTCMinutes(),
        _time.getUTCSeconds()
    );
  }

  _dispatchCurrentTimeEvent(time) {
    // TODO: use a while loop to have a more efficient sub-shadowDom inquisition
    const _t = this.shadowRoot.querySelectorAll('rux-timeline-track');
    _t.forEach((track) => {
      const _r = track.shadowRoot.querySelectorAll('rux-timeline-region');

      _r.forEach((region) => {
        // handles setting a timeline region to a past state
        const oldTemporality = region.temporality;
        if (time > this._getUTC(region.startTime) && time < this._getUTC(region.endTime)) {
          region.temporality = 'present';
        } else if (time < this._getUTC(region.startTime)) {
          region.temporality = 'future';
        } else if (time > this._getUTC(region.endTime)) {
          region.temporality = 'past';
        }

        // if (region.temporality !== oldTemporality && oldTemporality !== 'unset') {
        if (region.temporality !== oldTemporality) {
          this.dispatchEvent(
              new CustomEvent('region-state-change', {
                detail: {
                  region: region,
                },
                composed: true,
                bubbles: true,
              })
          );
        }
      });
    });
  }

  _updateCurrentTime(time) {
    const now = new Date();
    const utc = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
    );
    const then = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0);

    // time of today, like right now
    const dif = utc.getTime() - then.getTime();
    const loc = (dif * this._ruler.offsetWidth) / this._duration;

    if (loc > this._ruler.offsetWidth) {
      this._currentTime.style.display = 'none';
    } else {
      this._currentTime.style.display = 'block';
      this._currentTime.style.left = loc + 'px';
      this._loc = loc + 'px';
      this.currentTimeAnimator = window.requestAnimationFrame(this._updateCurrentTime.bind(this));
    }

    // throttle dispatching events to regions
    if (!this._last || time - this._last >= 1 * 1000) {
      this._last = time;
      this.style.setProperty('--playhead', this._loc);
      this._dispatchCurrentTimeEvent(utc);
    }
  }

  _updateTimelineScale() {
    if (!this._tracks) return;
    // scale tracks container
    const scale = `${Number(this.scale)}%`;
    this._tracks.style.width = scale;
    this._track.style.width = scale;
  }

  _setParams() {
    this._updateTimelineScale();
    // This is a very ugly way of targeting grandchildren form a parent
    // but for demo it’ll have to do.
    const _t = this.shadowRoot.querySelectorAll('rux-timeline-track');
    _t.forEach((track) => {
      track.dispatchEvent(new CustomEvent('update'));
      const _r = track.shadowRoot.querySelectorAll('rux-timeline-region');

      _r.forEach((region) => {
        region.dispatchEvent(new CustomEvent('update'));
      });
    });

    /*
      This ensures the selected regions stays in the timeline viewport when
      opening/closing the detail pane. This would benefit from a more stringent
      evaluation in the future.
     */
    if (this.selectedRegion.startTime) {
      const scrollX = this.selectedRegion.dom.offsetLeft - this._track.offsetWidth / 2;
      this._track.scrollTo(scrollX, this._track.scrollTop);
    }
  }

  /*
    TODO: Consider moving the ruler to its own component entirely and moving
    all the tic/label logic there
  */
  _setTics() {
    // TODO: Allow for a start time that isn’t midnight of the current day
    const start = 0;

    // loop through the duration set for the timeline
    for (let i = start; i < this._durationHours; i++) {
      // create a new tic
      const tic = document.createElement('div');

      // generate a readable time label
      tic.innerHTML = `${i.toString().padStart(2, '0')}:00`;

      // append to child
      this._ruler.appendChild(tic);
    }
  }

  // force labels to mirror the scroll position of the data tracks
  // this is a pretty big hack and NOT a long term solution
  _scroll() {
    this._trackLabels.scrollTo(0, this._track.scrollTop);
  }
}
customElements.define('rux-timeline', RuxTimeline);
