import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/array-selector.js';
import '@polymer/polymer/lib/utils/path.js';

/* eslint-disable no-unused-vars */
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
/* eslint-enable no-unused-vars */

import template from './contact-item.html';
import css from './contact-item.css';
const utils = require('../common-utils.js');
/**
 * @polymer
 * @extends HTMLElement
 */
export class ContactItem extends PolymerElement {
  static get properties() {
    return {
      status: {
        type: String,
        value: 'ID missing',
      },
      satellite: {
        type: String,
        value: 'Satellite missing',
      },
      nextPass: {
        type: Number,
        computed: 'getDayOfYear(contactBeginTimestamp)',
      },
      contactBeginTimestamp: {
        type: Number,
      },
      formattedAos: {
        type: String,
        computed: 'get24HourTime(contactBeginTimestamp)',
      },
      contactEndTimestamp: {
        type: Number,
      },
      formattedLos: {
        type: String,
        computed: 'get24HourTime(contactEndTimestamp)',
      },
      groundstation: {
        type: String,
        value: 'Ground Station missing',
      },
      azimuth: {
        type: Number,
      },
      elevation: {
        type: Number,
      },
      state: {
        type: String,
      },
      expanded: {
        type: Boolean,
        value: false,
      },
    };
  }

  /* Define component-specific methods */

  setStatusIcon() {
    if (this.state == 'executing') return 'normal';
    if (this.state == 'failed') return 'serious';
    return 'off';
  }

  constructor() {
    super();
    /* Register utility methods */
    this.is = utils.is;
    this.capitalize = utils.capitalize;
    this.formatMachineTime = utils.formatMachineTime;
    this.formatReadableTime = utils.formatReadableTime;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  get24HourTime(timestamp) {
    const ms = parseInt(timestamp) * 1000;
    const d = new Date(ms);
    let hh = d.getUTCHours();
    if (hh < 10) {
      hh = '0' + hh;
    }
    let mm = d.getUTCMinutes();
    if (mm < 10) {
      mm = '0' + mm;
    }
    const formattedTime = hh + ':' + mm;
    return formattedTime;
  }

  getDayOfYear(timestamp) {
    const ms = parseInt(timestamp) * 1000;
    const d = new Date(ms);
    const thisYear = d.getUTCFullYear();
    const firstDayOfYear = new Date(thisYear, 0, 1);
    const firstTimeOfYear = Math.floor(firstDayOfYear.getTime() / 86400000);
    const today = Math.ceil(d.getTime() / 86400000);
    let doy = today - firstTimeOfYear;
    if (doy < 10) {
      doy = '00' + doy;
    } else if (doy < 100) {
      doy = '0' + doy;
    }
    return doy;
  }

  static get template() {
    return html([
      `<style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('contact-item', ContactItem);
