module.exports = {
  is(a, b) {
    return a === b;
  },

  isPlural(int) {
    return int === 1 ? '' : 's';
  },

  capitalize(val) {
    if (!val) return;
    return val.charAt(0).toUpperCase() + val.substring(1);
  },

  groupBy(array, key) {
    return array.reduce((a, b) => {
      (a[b[key]] = a[b[key]] || []).push(b);
      return a;
    }, {});
  },

  formatMachineTime(timestamp, timezone = 'UTC') {
    const time = new Date(timestamp);

    const utc = `
      ${time.getUTCFullYear()}-${time.getUTCMonth()}-${time.getUTCDate()} 
      ${time.getUTCHours()}:${time.getUTCMinutes()}:${time.getUTCSeconds()}:${time.getUTCMilliseconds()}
    `;
    const local = `
      ${time.getFullYear()}-${time.getMonth()}-${time.getDate()} 
      ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}
    `;

    return timezone.toLowerCase() === 'utc' ? utc : local;
  },

  formatReadableTime(timestamp, timezone = 'UTC', locale = 'en-US') {
    const time = new Date(timestamp);
    return new Date(time).toLocaleTimeString(locale, {
      timeZone: timezone,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
  },

  formatDayOfYear(timestamp) {
    const time = new Date(timestamp);

    function zeroFill(number, width) {
      width -= number.toString().length;
      if (width > 0) {
        return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
      }
      return number + ''; // always return a string
    }

    const doy =
      (Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) - Date.UTC(time.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000;
    return zeroFill(doy, 3);
  },
};
