module.exports = {
  spacecraft: [ // could also show orbit information, vehicle specs, etc, per craft
    {iron: '36372', revPrefix: '12'},
    {iron: '67985', revPrefix: '75'},
    {iron: '78957', revPrefix: '49'},
    {iron: '32628', revPrefix: '48'},
    {iron: '43879', revPrefix: '18'},
    {iron: '48476', revPrefix: '45'},
    {iron: '64417', revPrefix: '31'},
    {iron: '97382', revPrefix: '14'},
    {iron: '24768', revPrefix: '35'},
    {iron: '34818', revPrefix: '24'},
    {iron: '16448', revPrefix: '13'},
    {iron: '81474', revPrefix: '09'},
    {iron: '47534', revPrefix: '78'},
    {iron: '77651', revPrefix: '07'},
    {iron: '14876', revPrefix: '13'},
    {iron: '64781', revPrefix: '44'},
    {iron: '51355', revPrefix: '34'},
    {iron: '44855', revPrefix: '14'},
    {iron: '23148', revPrefix: '04'},
  ],

  groundStations: [
    {abbr: 'HTS', callsign: 'HULA', name: 'Hawaii Tracking Station'},
    {abbr: 'VTS', callsign: 'COOK', name: 'Vandenburg Tracking Station'},
    {abbr: 'TTS', callsign: 'POGO', name: 'Thule Tracking Station'},
    {abbr: 'NHS', callsign: 'BOSS', name: 'New Hampshire Station'},
    {abbr: 'TCS', callsign: 'LION', name: 'Telemetry & Command Station'},
    {abbr: 'DGS', callsign: 'REEF', name: 'Diego Garcia Station'},
    {abbr: 'GTS', callsign: 'GUAM', name: 'Guam Tracking Station'},
  ],

  commonEquipmentConfigs: [
    {label: 'Default', string: 'ANT43 VAFB1 SFEP227CH1 ECEU6 WS402 USP177'},
    {label: 'Preset 1', string: 'ANT92 SAFB1 SFEP217CH1 ECEU6 WS299 USP432'},
    {label: 'Preset 2', string: 'ANT78 BAFB1 SFEP143CH1 ECEU6 WS130 USP451'},
    {label: 'Preset 3', string: 'ANT74 BAFB1 SFEP299CH1 ECEU6 WS305 USP451'},
    {label: 'Preset 4', string: 'ANT88 SAFB1 SFEP204CH1 ECEU6 WS249 USP156'},
  ],
};

