import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
// import {afterNextRender} from '@polymer/polymer/lib/utils/render-status.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

/**
 * @polymer
 * @extends HTMLElement
 */
export class AppMenu extends PolymerElement {
  static get properties() {
    return {
      orientation: {
        type: String,
        value: 'top',
      },
      hidden: {
        type: Boolean,
        value: true,
        reflectToAttribute: true,
        notify: true,
      },
      target: {
        type: Object,
        notify: true,
        observer: '_targetChanged',
      },
      menuItems: {
        type: Array,
      },
    };
  }

  static get template() {
    return html`
      <style>
        :host {
          display: block;

          font-size: 0.875rem;

          margin: 1em;

          min-width: 15em;
          max-width: 20em;
          position: relative;

          border: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          z-index: 10000;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        :host([hidden]) {
          display: none;
        }

        :host::before {
          content: '';
          display: block;
          position: absolute;

          width: 1.1875rem;
          height: 1.1875rem;

          background-color: #047cdc;
          z-index: 1;

          margin: -13px 0 0 15px;
          transform: rotate(45deg);
        }

        :host ul {
          position: relative;
          list-style: none;
          padding: 0;
          margin: 0;
          background: none;
          background-color: #fff;

          z-index: 2;
        }

        /* .rux-pop-up li,
      .satcom-pop-up li {
        border-bottom: 1px solid #f0f1f3;
      } */

        :host li:last-child {
          border: none;
        }

        :host a {
          display: block;
          padding: 0.5em;
          color: #000;
          text-decoration: none;

          min-width: 15em;
          max-width: 20em;

          word-wrap: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        :host a[dotted] {
          margin-top: 1rem;
          position: relative;
          overflow: visible;
        }

        :host a[dotted]:after {
          width: calc(100% - 2 * 10px);
          left: 10px;
          top: -0.5rem;
          position: absolute;
          content: '';
          border-top: 1px dashed #7d7d7d;
        }

        :host a:hover {
          /* font-weight: 700; */
          background-color: var(--colorSecondaryLighten3, rgb(211, 234, 255));
        }

        :host,
        .rux-pop-up--top {
          border-top: 3px solid var(--colorSecondary, rgb(77, 172, 255));
        }

        :host::before,
        .rux-pop-up--top::before {
          content: '';
          display: block;
          position: absolute;

          width: 1.1875rem;
          height: 1.1875rem;

          background-color: var(--colorSecondary, rgb(77, 172, 255));
          z-index: 1;

          margin: -13px 0 0 16px;
          transform: rotate(45deg);
        }

        .rux-pop-up--bottom {
          border-bottom: 3px solid var(--colorSecondary, rgb(77, 172, 255));
        }

        .rux-pop-up--bottom::after {
          content: '';
          display: block;
          position: absolute;
          border-bottom: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          border-right: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          width: 1.1875rem;
          height: 1.1875rem;

          background-color: var(--colorSecondary, rgb(77, 172, 255));

          margin: -6px 0 0 16px;
          transform: rotate(45deg);
        }

        .rux-pop-up--left {
          border-left: 3px solid var(--colorSecondary, rgb(77, 172, 255));
        }

        .rux-pop-up--left::before {
          content: '';
          display: block;
          position: absolute;
          border-bottom: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          border-left: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          width: 1.1875rem;
          height: 1.1875rem;

          background-color: var(--colorSecondary, rgb(77, 172, 255));

          margin: 16px 0 0 -13px;
          transform: rotate(45deg);
        }

        .rux-pop-up--right {
          border-right: 3px solid var(--colorSecondary, rgb(77, 172, 255));
        }

        .rux-pop-up--right::before {
          content: '';
          display: block;
          position: absolute;
          border-top: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          border-right: 1px solid var(--colorSecondary, rgb(77, 172, 255));
          width: 1.1875rem;
          height: 1.1875rem;

          background-color: var(--colorSecondary, rgb(77, 172, 255));

          right: 0;
          margin: 16px -13px 0 0;
          transform: rotate(45deg);
        }

        .rux-pop-up__underlay {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: red;
          opacity: 0.5;
          z-index: 9999;
        }

        /*
      
        .rux-pop-up--top-left
        .rux-pop-up--top-right
        .rux-pop-up--bottom-left
        .rux-pop-up--bottom-right
        
        .rux-pop-up--left-top
        .rux-pop-up--left-bottom
        .rux-pop-up--top-left
      
      */
      </style>

      <nav role="menu" id="pop-up-meu">
        <ul>
          <template is="dom-repeat" id="pop-up-menu" items="[[menuItems]]">
            <li>
              <a data-action$="[[item.action]]" dotted$="[[item.dotted]]" on-click="_menuClick">[[item.label]]</a>
            </li>
          </template>
        </ul>
      </nav>
    `;
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  _menuClick(e) {
    const menuChoice = e.model.item;

    if (menuChoice.action === 'visitUrl' && !!menuChoice.url) {
      window.open(menuChoice.url, '_blank');
    } else {
      alert('This feature has not been implemented.');
    }

    this.hidden = true;
    this.target = {};
  }

  listenForExternalEvents() {
    // Handle clicks external to the tooltip
    const externalClickListener = (event) => {
      const isMenu = event.composedPath().findIndex((path) => {
        return path.id === 'pop-up-meu';
      });

      const isPopupTrigger = event.composedPath().findIndex((path) => {
        return path === this.target;
      });

      if (isMenu < 0) {
        this._toggleCase = isPopupTrigger >= 0 ? true : false;
        this.target = {};
        this.hidden = true;
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('mousedown', externalClickListener);
    };
    document.addEventListener('mousedown', externalClickListener);
  }

  setPosition() {
    const _targetBounds = this.target.getBoundingClientRect();
    const _popUpBounds = this.getBoundingClientRect();

    let _left = _targetBounds.left;
    let _top = _targetBounds.bottom;

    // Handle edge cases where the menu might be off screen
    if (_targetBounds.left + _popUpBounds.width > window.innerWidth) {
      _left = window.innerWidth - _popUpBounds.width;
    } else if (_targetBounds.bottom + _popUpBounds.height > window.innerHeight) {
      _top = window.innerHeight - _popUpBounds.height;
    }

    const _css = `
            position: fixed;
            top: ${_top}px;
            left: ${_left}px;
            z-index: 10000`;

    this.setAttribute('style', _css);
  }

  _targetChanged(newValue) {
    if (this._isEmptyObject(newValue)) {
      // hiding the menu
      this.hidden = true;
    } else {
      if (this._toggleCase && this._isEmptyObject(this._oldValue)) {
        this._oldValue = {};
        this.target = {};
        this._toggleCase = false;
      } else {
        this.setPosition();
        this.hidden = false;

        this.listenForExternalEvents();
      }
    }
  }

  /* good candiate for common.utils.js */
  _isEmptyObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}

customElements.define('app-menu', AppMenu);
