import config from '../../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';
import {afterNextRender} from '@polymer/polymer/lib/utils/render-status.js';

import template from './tooltip.html';
import css from './tooltip.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class Tooltip extends PolymerElement {
  static get properties() {
    return {
      data: {
        type: Object,
        value: () => {},
        observer: '_dataChanged',
      },
      // @note watched should probably be moved to the data object in
      // or removed in an abstracted version
      watched: {
        type: Boolean,
        value: false,
      },
      hidden: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
      },
    };
  }

  static get observers() {
    return ['_watchThis(data.data.watched)'];
  }

  listenForExternalEvents() {
    // Handle clicks external to the tooltip
    const externalClickListener = (event) => {
      // traverse the dompath for the clicked event and see if
      // there’s a tooltip id in its path. Probably fragile for
      // full component use as there’s the possibility of another
      // tooltip id element
      const isTooltip = event.composedPath().findIndex((path) => {
        return path.id === 'tooltip';
      });

      const isTooltipTarget = event.composedPath().findIndex((path) => {
        return path.id === this.data.target.id;
      });

      // if (isTooltip < 0 || isTooltipTrigger < 1) {
      if (isTooltip < 0 && isTooltipTarget <= 0) {
        removeClickListener();

        this._toggleCase = isTooltipTarget >= 0 ? true : false;
        this.data = {};
      }
    };

    const parent = this.data.path[0];
    const removeClickListener = () => {
      parent.removeEventListener('scroll', externalClickListener);
      // this may be better with a throttled click event
      document.removeEventListener('mouseup', externalClickListener);
      window.removeEventListener('resize', this._setPosition);
    };

    // remove tooltip on scroll for now. Maybe enable
    // an attach on scroll in the future
    parent.addEventListener('scroll', externalClickListener);
    document.addEventListener('mouseup', externalClickListener);
    window.addEventListener('resize', this._setPosition);
  }

  setPosition() {
    const tooltip = this.shadowRoot.getElementById('tooltip');
    const targetRectangle = this.data.target.getBoundingClientRect();
    const tooltipRectangle = tooltip.getBoundingClientRect();

    const buffer = 20;

    const left = targetRectangle.left + targetRectangle.width;
    let top = targetRectangle.top;
    let caretTop = 50;

    // handles tooltips appearing off screen in a horizontal access.
    // full implementation of a proper tooltip should account for vertical
    // obstruction as well
    if (top + tooltipRectangle.height >= window.innerHeight - buffer) {
      top = window.innerHeight - (tooltipRectangle.height + buffer);

      // I know 12 looks like some magic numbers but it's not.
      // it is a bit brittle if the value of the border is changed
      // in CSS, but getting psuedo element values in JS is proper
      // difficult in components and a bit ugly. Maybe a refactor
      caretTop += targetRectangle.top - top + targetRectangle.height / 2 - 12;

      // don't let the caret extend beyond the tooltip's borders
      // probably need a better solution long term as this will
      // have a tendency to misalign if the window is resized. Again
      // looks like a magic number, but it's just double the height
      // of the non-rotated caret
      if (caretTop > tooltipRectangle.height - 24) {
        caretTop = tooltipRectangle.height - 24;
      }

      tooltip.style.setProperty('--caretTop', `${caretTop}px`);
    }

    tooltip.style.setProperty('--caretTop', `${caretTop}px`);
    this.style.left = `${left}px`;
    this.style.top = `${top}px`;
  }

  /*
    Rather than set the visibility of the component explicitly
    it listens for changes to its data component. If its empty
    then it hides, if there’s data then it stays visible
  */
  _dataChanged(newValue, oldValue) {
    // if the data is set to an empty object then hide the tooltip

    const isEmpty = this._isEmptyObject(newValue);

    if (isEmpty) {
      this.hidden = true;

      // but also check to see if there's an old value and if so
      // then set a private property of _oldValue. This handles
      // the toggle effect
      if (oldValue && !this._isEmptyObject(oldValue) && this._toggleCase) {
        this._oldValue = oldValue;
      }
      // check to see if _oldValue exists and if it does does it match the currently
      // selected toggle target. If so then reset the _oldValue so we can re-open
      // this toggle if necessary
    } else if (this._oldValue && this._oldValue.target.id === newValue.target.id) {
      this._oldValue = null;
      // otherwise show the toggle
    } else {
      this.hidden = false;

      afterNextRender(this, () => {
        this.setPosition();
        this.listenForExternalEvents();
      });
    }
  }

  _watchThis(value) {
    if (value === undefined) return;

    window.dispatchEvent(
        new CustomEvent('watch', {
          detail: {
            target: this.data.data._id,
            value: value,
            substep: this.data.substep || false,
          },
        })
    );
  }

  /* good candiate for common.utils.js */
  _isEmptyObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  constructor() {
    super();
    this.investigateUrl = config.menuItems[2].url;
    this._setPosition = this.setPosition.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
  }

  ready() {
    super.ready();
  }

  static get template() {
    return html([
      ` <style include="astro-css">
          ${css}
        </style> 
        ${template}`,
    ]);
  }
}

customElements.define('ttc-tooltip', Tooltip);
