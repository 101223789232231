import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import * as d3 from 'd3';

import template from './ttc-watcher-graph.html';
import css from './ttc-watcher-graph.css';

/**
 * @polymer
 * @extends HTMLElement
 */
export class TtcWatcherGraph extends PolymerElement {
  static get properties() {
    return {
      satellite: {
        type: String,
        value: 'IRON 4090',
      },
      mnemonic: {
        type: String,
        value: 'PWBVTLM',
      },
    };
  }

  static get template() {
    return html([
      `
        <style include='astro-css'>
          ${css}
        </style> 
        ${template}`,
    ]);
  }

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // Build chart initially - this will not call update
    this._buildLineChart(false);

    // Add event listener for when a mnemonic is selected from the Watcher pane
    window.addEventListener('mnemonicSelected', (e) => {
      // Set the mnemonic name
      this.mnemonic = e.detail.message;
      // Call building the chart but pass a boolean to tell it to remove the old chart (svg element)
      this._buildLineChart(true);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    // Remove event listener
    window.removeEventListener('mnemonicSelected');
  }

  ready() {
    super.ready();
  }

  _buildLineChart(update) {
    // Get the handle to the chart
    const chartHandle = this._getChart();

    // Set the maximum random value
    const maxRandomValue = 110;

    // Before or after current time - need to let the getHour function know whether to add or subtract
    const beforeCurrentHour = true;

    // Default sample data. It contains the current hour (then decrementing down by the hour)
    // and random numbers between 0 - 110
    const dataSet = [
      {
        // Get the current hour
        time: getHour(!beforeCurrentHour, 0),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 1
        time: getHour(beforeCurrentHour, 1000 * 60 * 60),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 2
        time: getHour(beforeCurrentHour, 1000 * 60 * 120),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 3
        time: getHour(beforeCurrentHour, 1000 * 60 * 180),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 4
        time: getHour(beforeCurrentHour, 1000 * 60 * 240),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 5
        time: getHour(beforeCurrentHour, 1000 * 60 * 300),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 6
        time: getHour(beforeCurrentHour, 1000 * 60 * 360),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 7
        time: getHour(beforeCurrentHour, 1000 * 60 * 420),
        value: generateRandomNumber(maxRandomValue),
      },
      {
        // Get the current hour - 8
        time: getHour(beforeCurrentHour, 1000 * 60 * 480),
        value: generateRandomNumber(maxRandomValue),
      },
    ];

    // Set the margins of the line chart
    const margin = {
      top: 15,
      right: 20,
      bottom: 35,
      left: 40,
    };

    // Set the upper and lower thresholds for the Y Axis grid
    const upperThreshold = 100;
    const lowerThreshold = 20;

    // Set the width and height of the line chart
    const width = 950 - margin.left - margin.right;
    const height = 325 - margin.top - margin.bottom;

    // Scale both the X and Y axis
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    // Format the Y axis
    const yAxis = d3
        .axisLeft(y)
        .tickFormat(function(d) {
          return d;
        })
        .ticks(10);

    // Gridlines in y axis function
    function makeYGridlines() {
      return d3.axisLeft(y).ticks(10);
    }

    // Define the line
    const valueLine = d3
        .line()
        .x(function(d) {
          return x(d.time);
        })
        .y(function(d) {
          return y(d.value);
        });

    // If we are updating, just remove the chart svg element
    if (update === true) {
      d3.select(this.shadowRoot.querySelector('svg')).remove();
    }

    // Get the chart element
    const svg = d3
        .select(chartHandle)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 950 325')
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    // Get and format the data
    dataSet.forEach(function(d) {
      d.value = +d.value;
    });

    // Scale the range of the data
    x.domain(
        d3.extent(dataSet, function(d) {
          return d.time;
        })
    );
    y.domain([
      0,
      d3.max(dataSet, function() {
        return Math.max(0, maxRandomValue);
      }),
    ]);

    // Add the valueLine path.
    svg
        .append('path')
        .data([dataSet])
        .attr('class', 'line')
        .attr('id', 'line')
        .style('stroke', '#4DACFF')
        .attr('d', valueLine);

    // Add the X Axis
    svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'axis')
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat('%H' + '00'))); // Format the time label

    // Add the Y Axis
    svg
        .append('g')
        .attr('class', 'axis')
        .call(yAxis);

    // Text label for the y axis
    svg
        .append('text')
        .attr('class', 'label-text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - height / 2)
        .attr('dy', '1.2em')
        .text('Volts');

    // Add the Y gridlines
    svg
        .append('g')
        .attr('class', 'grid')
        .attr('opacity', 0.5)
        .call(
            makeYGridlines()
                .tickSize(-width)
                .tickFormat('')
        );

    // Threshold line and text group
    const thresholdText = svg.append('g').attr('class', 'upper-threshold-text');

    // Upper Threshold text
    thresholdText
        .append('text')
        .attr('class', 'threshold-text')
        .attr('y', y(upperThreshold))
        .attr('dy', '12px')
        .attr('x', '425px')
        .text('Upper Limit');

    // Lower Threshold text
    thresholdText
        .append('text')
        .attr('class', 'threshold-text')
        .attr('y', y(lowerThreshold))
        .attr('dy', '12px')
        .attr('x', '425px')
        .text('Lower Limit');

    // Upper threshold bold line
    thresholdText
        .append('line')
        .attr('class', 'threshold-line')
        .attr('x1', 0)
        .attr('y1', y(upperThreshold))
        .attr('x2', width)
        .attr('y2', y(upperThreshold));

    // Lower threshold bold line
    thresholdText
        .append('line')
        .attr('class', 'threshold-line')
        .attr('x1', 0)
        .attr('y1', y(lowerThreshold))
        .attr('x2', width)
        .attr('y2', y(lowerThreshold));

    // This function call will be put inside an event handler once merged with the Watcher
    chartUpdate();

    // This function will update the chart everytime a new Mnemonic is selected
    function chartUpdate() {
      // Scale the range of the data again
      x.domain(
          d3.extent(dataSet, function(d) {
            return d.time;
          })
      );
      y.domain([
        0,
        d3.max(dataSet, function() {
          return Math.max(0, maxRandomValue);
        }),
      ]);
      // Select the chart
      const svgUpdate = d3.select(chartHandle).transition();
      // Update the line
      svgUpdate
          .select('.line') // change the line
          .duration(750)
          .attr('d', valueLine(dataSet));
    }
  }

  _getChart() {
    return this.shadowRoot.querySelector('#lineChart');
  }
}
customElements.define('ttc-watcher-graph', TtcWatcherGraph);

function generateRandomNumber(max) {
  return Math.floor(Math.random() * max) + 1;
}

// This will return a previous hour, current hour, or future hour
function getHour(beforeCurrentHour, hour) {
  // Get current date
  const currentTimeDate = new Date();
  const offset = 1000 * 60 * currentTimeDate.getTimezoneOffset();

  if (beforeCurrentHour) {
    return currentTimeDate.getTime() - hour + offset;
  } else {
    return currentTimeDate.getTime() + hour + offset;
  }
}
