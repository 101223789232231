/* eslint-disable no-unused-vars */
import config from '../config/config.json';
import {html, PolymerElement} from '@polymer/polymer/polymer-element.js';

/* Astro Components */
import {RuxClock} from '@astrouxds/rux-clock/rux-clock.js';
import {RuxGlobalStatusBar} from '@astrouxds/rux-global-status-bar/rux-global-status-bar.js';
import {RuxIcon} from '@astrouxds/rux-icon/rux-icon.js';
import {RuxStatus} from '@astrouxds/rux-status/rux-status.js';
import {RuxSegmentedButton} from '@astrouxds/rux-segmented-button/rux-segmented-button.js';

/* GRM Services Templates and Styles */
import {AppMenu} from './app-menu.js';
import {StatusIndicators} from './status/status-indicators';
import template from './astro-app.html';
import css from './astro-app.css';
import astroCSS from './astro-css.js';
import contactService from './contacts/contact-service.js';
const utils = require('./common-utils.js');
const contactData = require('./contacts/contact-data.js');

/*
    TT&C Monitor Components
    Add TT&C Monitor specific components here
*/
import {GrmAlerts} from './alerts/alerts.js';
import {DetailPane} from './detail-pane/detail-pane.js';
import {RuxTimeline} from './timeline/rux-timeline.js';
import {RuxSlider} from './slider/rux-slider.js';
import {CurrentContacts} from './contacts/current-contacts.js';
import {TtcWatcherGraph} from './ttc-watcher/ttc-watcher-graph.js';
import {TtcWatcher} from './ttc-watcher/ttc-watcher.js';
import {Tooltip} from './tooltip/tooltip.js';
// import {GrmContactsSummary} from './contacts/contacts-summary.js';

/* eslint-enable no-unused-vars */
/**
 * @polymer
 * @extends HTMLElement
 */
export class AstroApp extends PolymerElement {
  static get template() {
    return html([
      `
      <style include="astro-css">
        ${css}
      </style> 
      ${template}`,
    ]);
  }

  static get observers() {
    return ['updateContactsInPass(contactsInPass.*)'];
  }
  static get properties() {
    return {
      tooltipData: {
        type: Object,
        value: () => {
          return {};
        },
      },
      views: {
        type: Array,
        value: [{label: 'Timeline', value: 'timeline'}, {label: 'List', value: 'list'}],
      },
      grmSchedule: {
        type: Array,
        value: [],
      },
      currentlySelectedRegion: {
        type: Object,
        value: {},
        observer: '_selectedRegionChanged',
      },
      detailPaneOpen: {
        type: Boolean,
        value: false,
      },
      statusData: {
        type: Object,
        value: {
          ucaStatus: {worstStatus: 'standby', numMessages: 45},
          timestamp: 1546641719633,
        },
      },
      contactsInPass: {
        type: Array,
        value: [],
      },
      constellationView: {
        type: Array,
        value: [
          {
            label: 'List',
            list: true,
            selected: true,
          },
          {
            label: 'Timeline',
            list: false,
          },
        ],
      },
      passPlanSteps: {
        type: Array,
        value: [
          {
            _id: 'step-0001',
            label: 'HM1114RPW',
            type: 'single-step',
            expectedValue: 'on',
            actualValue: 'on',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0002',
            label: 'VRCPEDACEN',
            type: 'single-step',
            expectedValue: 'enabled',
            actualValue: 'enabled',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0003',
            label: 'VRHMCRD',
            type: 'single-step',
            expectedValue: 'open',
            actualValue: 'open',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: true,
          },
          {
            _id: 'step-0004',
            label: 'ADST2S3IN4',
            type: 'single-step',
            expectedValue: '< 1.25 Magnitude',
            actualValue: '1.23 Magnitude',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: true,
            watched: false,
          },
          {
            _id: 'step-0005',
            label: 'Mnemonic',
            type: 'two-part',
            prompts: ['Is "XYZ" selected?', 'Are you receiving telemetry?'],
          },
          {
            _id: 'step-0006',
            label: '81001',
            type: 'multi-step',
            progress: 1,
            playState: 'unplayed',
            expanded: false,
            steps: [
              {
                _id: 'step-0006-1',
                label: 'DTU1L2PW',
                type: 'single-step',
                expectedValue: '1',
                actualValue: '1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
              {
                _id: 'step-0006-2',
                label: 'GCEGNXTK',
                type: 'single-step',
                expectedValue: 'sel',
                actualValue: 'sel',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0006-3',
                label: 'P1ABATI1',
                type: 'single-step',
                expectedValue: 'RGP 1',
                actualValue: 'RGP 1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0006-4',
                label: 'OMCPEP28I',
                type: 'single-step',
                expectedValue: '</=60 Amps',
                actualValue: '59 Amps',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
            ],
          },
          {
            _id: 'step-0007',
            label: 'HM1516RPW',
            type: 'single-step',
            expectedValue: 'on',
            actualValue: 'on',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0008',
            label: 'VRPS30SCCEN',
            type: 'single-step',
            expectedValue: 'enabled',
            actualValue: 'enabled',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0009',
            label: 'OMBTSEL0',
            type: 'single-step',
            expectedValue: 'open',
            actualValue: 'open',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: false,
            watched: false,
          },
          {
            _id: 'step-0010',
            label: '81002',
            type: 'multi-step',
            progress: 1,
            playState: 'unplayed',
            expanded: false,
            steps: [
              {
                _id: 'step-0010-1',
                label: 'ADTR3PW2',
                type: 'single-step',
                expectedValue: '1',
                actualValue: '1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
              {
                _id: 'step-0010-2',
                label: 'GCEGNYOC',
                type: 'single-step',
                expectedValue: 'sel',
                actualValue: 'sel',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0010-3',
                label: 'P1AESBV1',
                type: 'single-step',
                expectedValue: 'RGP 1',
                actualValue: 'RGP 1',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: false,
                watched: false,
              },
              {
                _id: 'step-0010-4',
                label: 'OMNPTECI',
                type: 'single-step',
                expectedValue: '</=60 Amps',
                actualValue: '59 Amps',
                subsystem: 'Attitude',
                complete: false,
                hasGraph: true,
                watched: false,
              },
            ],
          },
          {
            _id: 'step-0011',
            label: 'ADST1S2IN7',
            type: 'single-step',
            expectedValue: '< 1.25 Magnitude',
            actualValue: '1.23 Magnitude',
            subsystem: 'Attitude',
            complete: false,
            hasGraph: true,
            watched: false,
          },
        ],
      },
      sampleAlerts: {
        type: Array,
        value: [
          {
            errorId: '6d76630e-e99f-5615-9bd8-331a0fc4b955',
            errorSeverity: 'caution',
            errorCategory: 'software',
            errorMessage: 'Red FEP 121 - Offline',
            longMessage: 'Red FEP 121 is offline at 18:37:45',
            errorTime: 1542134265725,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '20a96646-abbc-5195-9b20-cff2e99f2ada',
            errorSeverity: 'alert',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-168 - Power degradation',
            longMessage: 'USA-168 suffered power degradation at 18:37:54',
            errorTime: 1542134274738,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: 'e7d304c2-17ef-5426-ac70-4431fa580409',
            errorSeverity: 'alert',
            errorCategory: 'software',
            errorMessage: 'Black FEP 121 - Degraded',
            longMessage: 'Black FEP 121 is degraded at 18:37:57',
            errorTime: 1542134277742,
            selected: false,
            new: false,
            expanded: false,
          },
          {
            errorId: '47e8c77b-227e-5606-8718-7fafb67f8f8b',
            errorSeverity: 'caution',
            errorCategory: 'spacecraft',
            errorMessage: 'USA-150 - Solar panel misalignment',
            longMessage: 'USA-150 experienced solar panel misalignment at 18:38:00',
            errorTime: 1542134280747,
            selected: false,
            new: false,
            expanded: false,
          },
        ],
      },
      constellationLog: {
        type: Array,
        observer: '_constellationLogUpdated',
        value: () => [],
      },
      alertsLog: {
        type: Array,
        value: [],
      },
    };
  }

  constructor() {
    super();
    this._tooltipListener = this._showTooltip.bind(this);

    this.addEventListener('closeDetailPane', () => this.toggleDetailPane('closed'));
    this._regionStateChange = this.regionStateChange.bind(this);

    this.timelineScale = 800;
    this.inPrePassTime = 300;

    this.inPassString = null;
    this.removePassTimer = null;
  }

  _startStatusIndicatorsWebsocket() {
    const ws = new WebSocket(config.dataServers.statusIndicators);
    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.statusData = payload;
    });
  }

  _selectedRegionChanged() {
    if (this.currentlySelectedRegion.label && !this.detailPaneOpen) {
      this.toggleDetailPane('open');
      // } else if (!this.currentlySelectedRegion._id && this.detailPaneOpen) {
      //   this.toggleDetailPane('close');
    }
  }

  toggleDetailPane(force) {
    switch (force) {
      case 'open':
        this.detailPaneOpen = true;
        break;
      case 'closed':
        this.detailPaneOpen = false;
        break;
      default:
        this.detailPaneOpen = !this.detailPaneOpen;
        break;
    }
  }

  displayPopUpMenu(e) {
    if (e.detail.menu) {
      this.appMenuTarget = e.detail.target;
      this.menuItems = e.detail.menu;
    } else {
      this.appMenuTarget = e.currentTarget;
      this.menuItems = config.menuItems;
    }
  }
  _showTooltip(event) {
    // Cheating a bit here to get the scrollable element the tooltip is housed in
    // there is only one place this is used in the component
    // @todo find a better way to handle scroll
    event.detail.path = event.composedPath();
    if (event.detail.target.getAttribute('data-substep') != null) {
      event.detail.substep = true;
    }
    this.set('tooltipData', event.detail);
  }

  _startAlertsWebsocket() {
    const ws = new WebSocket(config.dataServers.alerts);

    ws.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      payload.selected = false;
      payload.expanded = false;
      payload.new = true;
      const index = this.alertsLog.length;
      this.push('alertsLog', payload);

      setTimeout(() => {
        this.set(['alertsLog', index, 'new'], false);
      }, 2000);
    });

    /* Event Listeners for Alerts */
    this.addEventListener('removeAlerts', this._removeItemsFromAlertsLog);
    this.addEventListener('alertSelectionChanged', this._setAlertSelectedState);
    this.addEventListener('alertToggleChanged', this._setAlertExpandedState);
    this.addEventListener('contactToggleChanged', this._setContactExpandedState);

    /* Populate Alerts with a random sample on load */
    // for (const alert in this.sampleAlerts) {
    //   this.push('alertsLog', this.sampleAlerts[alert]);
    // }
    for (let a = 0; a < this.sampleAlerts.length; a++) {
      const alert = this.sampleAlerts[a];
      this.push('alertsLog', alert);
    }
  }

  isAlertVisible(status, category, alert) {
    if (status !== 'all' && status !== alert.errorSeverity) return false;
    if (category !== 'all' && category !== alert.errorCategory) return false;
    return true;
  }

  _removeItemsFromAlertsLog(event) {
    const alertsToKeep = this.alertsLog.filter((alert) => {
      const isVisible = this.isAlertVisible(event.detail.activeStatusFilter, event.detail.activeCategoryFilter, alert);
      // you can stay in the log if you are invisible or not selected
      return !isVisible || !alert.selected;
    });

    this.set('alertsLog', alertsToKeep);
  }

  _setAlertSelectedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'selected'], event.detail.setSelectedTo);
        this.notifyPath(['alertsLog', index, 'selected']);
      }
    });
  }

  _setAlertExpandedState(event) {
    this.alertsLog.forEach((alert, index) => {
      if (alert.errorId === event.detail.errorId) {
        this.set(['alertsLog', index, 'expanded'], event.detail.setExpandedTo);
        this.notifyPath(['alertsLog', index, 'expanded']);
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this._startStatusIndicatorsWebsocket();
    this._startAlertsWebsocket();
    this._getCurrentContacts();
    this.grmSchedule = contactService();
    this.menuItems = config.menuItems;
    this.investigateURL = config.menuItems[1].url;
    this.user = config.user;

    this.addEventListener('showPopUpMenu', this.displayPopUpMenu);
    this.addEventListener('region-state-change', this._regionStateChange);

    window.addEventListener('showTooltip', this._tooltipListener);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('pop-up-menu-event', this._navigateToPage);
    this.removeEventListener('showPopUpMenu', this.displayPopUpMenu);
    this.removeEventListener('region-state-change', this._regionStateChange);

    window.removeEventListener('showTooltip', this._tooltipListener);
  }

  closeNotificationBanner() {
    this.inPassString = null;
  }

  updateContactsInPass() {
    if (this.contactsInPass.length) {
      const _contactsInPass = this.contactsInPass.map((contact) => {
        return contact.label;
      });

      this.inPassString = `${_contactsInPass.join(', ')} ${_contactsInPass.length > 1 ? 'are' : 'is'} `;
    } else {
      this.inPassString = null;
    }
  }

  regionStateChange(event) {
    const contactIndex = this.contactsInPass.findIndex((contact) => {
      return contact.contactId === event.detail.region.contactId;
    });

    const now = new Date();
    const isInPrePass = Math.round(now.getTime() / 1000) < event.detail.region.startTime + this.inPrePassTime;

    if (event.detail.region.temporality === 'present' && contactIndex < 0 && isInPrePass) {
      this.push('contactsInPass', {
        label: event.detail.region.label,
        contactId: event.detail.region.contactId,
        time: event.detail.region.startTime,
      });
    } else if (event.detail.region.temporality !== 'present' && contactIndex >= 0) {
      this.splice('contactsInPass', contactIndex, 1);
    }

    if (!this.removePassTimer) {
      this.removePassTimer = setInterval(() => {
        const now = new Date().getTime();
        this.contactsInPass.forEach((contact, index) => {
          if (now / 1000 >= contact.time + this.inPrePassTime) {
            this.splice('contactsInPass', index, 1);
          }
        });
      }, 1000);
    }
  }

  timelineIsSelected() {
    return false;
  }

  _mapContactState(status) {
    switch (status.toLowerCase()) {
      case 'prepass':
      case 'pre-pass': {
        return 'standby';
      }
      case 'pass': {
        return 'normal';
      }
      case 'scheduled':
      case 'complete':
      default: {
        return 'off';
      }
    }
  }
  /*
   * * Transforms the constellation log, grouping the flat structure by
   * * contactSatellite
   */

  _constellationLogUpdated() {
    const groupKey = 'contactSatellite';

    // In a real world app it would be ideal for all data services
    // to standardize on a consistent property naming structure so
    // all elements in the app consuming any sort of status or label
    // can always use the same key.

    // in the meantime this remaps data service values to expected
    // values in the timeline
    const keyMap = {
      contactBeginTimestamp: 'startTime',
      contactEndTimestamp: 'endTime',
      contactGround: 'label',
      contactStatus: 'status',
    };

    // create a clone of an the constellationLog to avoid polluting
    // the table data.
    //
    // @todo all of this copy/mapping stuff probably means re-thinking
    // the data structures. Creating a new copy of the original array
    // will inevitably break partiy between the two lists or require
    // some convoluted update method.
    const _clonedConstellation = [...this.constellationLog];

    _clonedConstellation.forEach((entry) => {
      entry.detail = {
        azimuth: entry.contactAzimuth,
        elevation: entry.contactElevation,
        detail: entry.contactDetail,
        satellite: entry.contactSatellite,
        state: this._mapContactState(entry.contactResolution),
      };
    });

    // remap all start and end time values. the array structure should be the same
    // at the end of this method
    _clonedConstellation.forEach((entry, index, array) => {
      // loop through each entries properties and replace contactBeginTimestamp
      // and contactEndStamp with startTime and endTime for consumption by the
      // timeline component
      array[index] = Object.keys(entry).reduce((acc, key) => {
        // create a new object
        const remappedObj = {
          // see if there's a matching value in the keyMap object and if so
          // use that as the key, if not use the original key. Assign the
          // value to the new (or existing) key
          [keyMap[key] || key]: entry[key],
        };

        // merge and return the acc(umulator) value with the remapped object
        return {
          ...acc,
          ...remappedObj,
        };
      }, {});
    });

    // group the consteallation list by its contact satellite set in groupKey cosnt
    const _groupedConsteallation = _clonedConstellation.reduce((acc, val) => {
      (acc[val[groupKey]] = acc[val[groupKey]] || []).push(val);
      return acc;
    }, []);

    // create a new array of objects that have the structure expected by the timeline
    // essentially a label property followed by an array of satellites at the end of
    // this an array entry should look like { label: "IRON", data: []}
    const _labeledConstellation = Object.keys(_groupedConsteallation).map((key) => {
      return {label: key, data: _groupedConsteallation[key]};
    });

    this.constellationLogForTimeline = _labeledConstellation;
  }

  _getCurrentContacts() {
    fetch(config.dataServers.contacts)
        .then((response) => {
          return response.json();
        })
        .then((theJson) => {
          this.set('constellationLog', theJson);
        });
  }

  ready() {
    super.ready();
  }
}
customElements.define('astro-app', AstroApp);
